import bg from "./images/balkanation_tv.png";

function App() {
  return (
    <div className="bg-gradient-to-b from-white to-violet-100 h-screen w-screen flex flex-col justify-between items-center">
      <div className="my-4">
        <p class="uppercase text-purple-900 font-bold text-xs sm:text-lg md:text-2xl">
          this website is currently under construction.
        </p>
      </div>
      <div>
        <p className="text-xs sm:text-md md:text-xl text-purple-900">
          Hi there! Welcome to...
        </p>
      </div>
      <div className="">
        <img className="w-auto my-3 sm:my-5" alt="main_background" src={bg} />
      </div>
      <div>
        <p className="text-xs sm:text-md md:text-xl text-purple-900">
          "The Exclusive" Balkan Music Video Stream.
        </p>
      </div>
      <div className="text-center">
        <p className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-purple-900 pb-14 sm:pb-24">
          COMING SOON!
        </p>
        {/* <div className="text-center text-base sm:text-lg md:text-2xl text-purple-900 uppercase mb-5 sm:mb-8 sm:flex sm:justify-center sm:items-center">
          <div className="py-3 sm:py-0 sm:px-24 px-0">
            <a
              className="App-link"
              href="http://tuneyverse.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p> tuneyverse.com </p>
            </a>
          </div>
          <div className="py-3 sm:py-0 sm:px-24 px-0">
            <a
              className="App-link"
              href="http://lurvemoving.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p> lurvemoving.com </p>
            </a>
          </div>

          <div className="py-3 sm:py-0 sm:px-24 px-0">
            <a
              className="App-link"
              href="http://labalcanmusic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p> labalcanmusic.com </p>
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default App;

/* <div className="bg-gradient-to-tr from-red-500 to-purple-700 relative h-screen w-screen"> */

/* */

/* <div className=" flex flex-col justify-between w-3/6 max-w-lg mx-auto h-full text-center"> 

<h1 className="font-primary font-extrabold text-white text-6xl sm:text-4xl md:text-5xl capitalize">
          Balkanation
        </h1> */
/* <h1 className="font-primary font-extrabold text-white text-3xl sm:text-4xl md:text-5xl md:leading-tight">
            Easily capture emails on{" "}
            <span className="text-palette-primary">Notion.</span>
          </h1> */
